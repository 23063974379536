import variables from 'src/styles/variables'
import styled from 'styled-components'

export const SetupContainer = styled.div`
  max-width: 1200px;
  width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    max-width: 600px;
    width: 250px;
    padding: 10px;

`

export const DayContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  color: ${variables.darkGray};
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 768px) {
    display: grid;
    width: 250px;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    padding: 5px;
  }
`

export const Label = styled.label`
  display: flex;
  margin-top: 15px;
  margin-bottom: 5px;
`
export const Checkbox = styled.input`
  margin-right: 5px;
  cursor: pointer;
`

export const ControllerButton = styled.button`
  margin-left: 3px;
  border: 1px solid ${variables.darkGray};
  border-radius: 5px;
  cursor: pointer;
  background-color: ${variables.gray};
  color: ${variables.darkGray};
  height: 25px;
  width: 20px;
  font-size: 18px;
  font-weight: bold;
`

export const Submit = styled.button`
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${variables.darkGray};
  color: white;
  font-size: 16px;
  font-weight: bold;
  width: 80px;

  &:hover {
    background-color: ${variables.gray};
    color: ${variables.darkGray};
  }
`
export const ErrorMessage = styled.div`
  margin-top: 10px;
  color: red;
`
export const Options = styled.select`
  height: 24px;
  margin-bottom: 8px;
`
